import React from 'react'
import shopifyAppStoreLogoBlack from '../../public/Shopify-App-Store-Badge-Final-Black.svg'
import shopifyAppStoreLogoWhite from '../../public/Shopify-App-Store-Badge-Final-White.svg'

const AppStoreCTA = (props) => {

    const trackClick = () => {
        if (typeof document !== 'undefined') {
            window.analytics.track('Click to App Store', {
                source: window.location.href
            });
            const link = props.url ? props.url : 'https://apps.shopify.com/batch-offers';
            window.open(link, '_blank');
        }
    }

    const logoColor = () => {
        if (props.color === "white") {
            return shopifyAppStoreLogoWhite
        }
        if (props.color === "black") {
            return shopifyAppStoreLogoBlack
        }
        return shopifyAppStoreLogoBlack
    }

    return(
        <a rel="noreferrer" className="inline-flex items-center" onClick={() => trackClick()}>
            <img alt="Get Batch on the Shopify App Store" src={logoColor()} className="w-48" />
        </a>
    )
}

export default AppStoreCTA