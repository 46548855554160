import React from 'react'
import Layout from '../components/layout'
import { Link, graphql } from 'gatsby'
import BlogIndexCard from "../components/blog/blogIndexCard";
import TrialCTA from "../components/trialCta";
import merchantOfferView from '../../public/Merchant-Offer-View.svg'
import { Helmet } from 'react-helmet'

export const query = graphql`
    query ($slug: String!) {
        site {
            siteMetadata {
              blog {
                title
                description
              }
            }
          }
        ghostTag (slug: {eq: $slug}) {
            id
            name
            slug
            description
            feature_image
            visibility
            meta_title
            meta_description
            postCount
        }
        allGhostPost(filter: {tags: {elemMatch: {slug: {eq: $slug}}}}) {
            edges {
              node {
                id
                title
                visibility
                primary_author {
                    name
                    profile_image
                    slug
                }
                feature_image
                excerpt
                reading_time
                slug
                url
                published_at(formatString: "MMMM Do, YYYY")
                primary_tag {
                    name
                    slug
                }
              }
            }
          }
    }
`

const BlogCategory = (props) => {

    return (
        <>
        <Helmet>
        {/* Meta */}
          <html lang="en" />
          <title>{props.data.ghostTag.name} — {props.data.site.siteMetadata.blog.title}</title>
          <meta charSet="utf-8" />
          <meta name="title" content={`${props.data.ghostTag.name} — ${props.data.site.siteMetadata.blog.title}`}/>
          <meta name="description" content={props.data.ghostTag.description} /> 
          <link rel="canonical" href={props.data.ghostTag.slug} />
      </Helmet>
        <Layout>
        <div className="relative px-4 pt-16 pb-20 bg-gray-50 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
            <div className="absolute inset-0">
              <div className="bg-white h-1/3 sm:h-2/3"></div>
            </div>
            <div className="relative mx-auto max-w-7xl">
                <div className="text-center">
                    <h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                    {props.data.ghostTag.name}
                    </h1>
                    <p className="max-w-2xl mx-auto mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
                    {props.data.ghostTag.description}
                    </p>
                </div>

                <div className="grid max-w-lg gap-5 mx-auto mt-12 lg:grid-cols-3 lg:max-w-none">
                    {
                        props.data.allGhostPost.edges.forEach((post) => {
                            if (post.node.visibility === "public") {
                                return(
                                    <div className="flex flex-col overflow-hidden rounded-lg shadow-lg" id={`blog-card-${post.node.id}`}>
                                        <div className="flex-shrink-0">
                                            <Link to={`/${post.node.slug}`}><img className="object-cover w-full h-48" src={post.node.feature_image} alt=""/></Link>
                                        </div>
                                        <div className="flex flex-col justify-between flex-1 p-6 bg-white">
                                            <div className="flex-1">
                                                <Link to={`/${post.node.primary_tag.slug}`}>
                                                    <span className="inline-flex items-center px-1.5 py-0.5 text-sm font-medium leading-5 bg-indigo-700 text-white">
                                                        {post.node.primary_tag.name.toUpperCase()}
                                                    </span>
                                                </Link>
                                                <Link to={`/${post.node.slug}`} className="block">
                                                    <h3 className="mt-2 text-xl font-semibold leading-7 text-gray-900">
                                                        {post.node.title}
                                                    </h3>
                                                    <p className="mt-3 text-base leading-6 text-gray-500">
                                                        {post.node.excerpt}
                                                    </p>
                                                </Link>
                                            </div>
                                            <BlogIndexCard props={post.node} />
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </div>
        <TrialCTA 
            bgClass={"bg-indigo-600"}
            title={"Close the loop on expiring Shopify inventory."} 
            description={"Collect an unlimited number of offers on the Forever Free plan. Upgrade when you're ready to activate offers into revenue."} 
            buttonText={"Install Batch for free"} 
            url={"https://apps.shopify.com/batch-offers"} 
            image={merchantOfferView}
        />
      </Layout>
      </>
    )
}

export default BlogCategory