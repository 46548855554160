import React from 'react'
import AppStoreCTA from './appStoreCta'
import BatchCTA from './batchCta'

const TrialCTA = (props) => {
    return(
        <div>
            <div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className={`rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4 ${props.bgClass}`}>
                    <div className="px-6 pt-10 pb-12 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                        <div className="lg:self-center">
                            <h2 className="text-3xl font-extrabold leading-tight text-white sm:text-4xl">
                                <span className="block">{props.title}</span>
                            </h2>
                            <p className="mt-4 text-lg leading-6 text-indigo-200">{props.description}</p>
                            <div className="my-4">
                            <BatchCTA px="8" py="4" fontSize="lg" content="Start collecting offers"/>  
                            </div>
                            <AppStoreCTA color={"white"} url={props.url}/>
                        </div>
                    </div>
                        <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
                            <a href={props.url}>
                                <img className="object-cover object-left-top transform translate-x-6 translate-y-6 rounded-md sm:translate-x-16 lg:translate-y-20" src={props.image} alt="App screenshot" />
                            </a>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default TrialCTA