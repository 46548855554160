import React from 'react'
import { Link } from 'gatsby'

const BlogIndexCard = (props) => {
    return (
        <div className="flex items-center mt-6">
            <div className="flex-shrink-0">
                <Link to={`/authors/${props.props.primary_author.slug}`}>
                <img className="w-10 h-10 rounded-full" src={props.props.primary_author.profile_image} alt=""/>
                </Link>
            </div>
            <div className="ml-3">
                <p className="text-sm font-medium leading-5 text-gray-900">
                <Link to={`/authors/${props.props.primary_author.slug}`} className="hover:underline">
                    {props.props.primary_author.name}
                </Link>
                </p>
                <div className="flex text-sm leading-5 text-gray-500">
                <time datetime={props.props.published_at}>
                    {props.props.published_at}
                </time>
                <span className="mx-1">
                    &middot;
                </span>
                <span>
                    {props.props.reading_time} min read
                </span>
                </div>
            </div>
        </div>
    )
}

export default BlogIndexCard